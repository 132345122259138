import React from "react"

import ContinueLink from "../ContinueLink"

import styles from "./InfoBlock.module.scss"

const InfoCard = ({ category, title, text, link, right, topBlock }) => {
  let bodyClass = styles.infoCard
  if (right) bodyClass += ` ${styles["infoCard--right"]}`
  if (topBlock) bodyClass += ` ${styles["infoCard-pd0"]}`

  return (
    <div className={bodyClass}>
      <div className={styles.infoCard__category}>{category}</div>
      <div className={styles.infoCard__title}>{title}</div>
      <div className={styles.infoCard__text}>{text}</div>
      {link && <ContinueLink to={link} label="Read more" />}
    </div>
  )
}

const InfoBlock = ({
  className,
  category,
  title,
  text,
  link,
  image,
  right,
  topBlock,
}) => (
  <div className={`${styles.mainBox} ${className}`}>
    <div className={`${styles.mainBox__content}`}>
      <InfoCard
        topBlock={topBlock}
        category={category}
        title={title}
        text={text}
        link={link}
        right={right}
      />
      <div className={styles.image} style={right ? { order: 1 } : undefined}>
        {image}
      </div>
    </div>
  </div>
)

export default InfoBlock
