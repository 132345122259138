import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby";
import { remark } from "remark";
import remarkHtml from "remark-html";

import Layout from "../components/Layout"
import ContinueLink from "../components/ContinueLink"
import InfoBlock from "../components/InfoBlock"
import ClientsBlock from "../components/ClientsBlock"
import ContactForm from "../components/ContactForm"
import ContactInfo from "../components/ContactInfo"
import GoogleMaps from "../components/GoogleMaps"

import useTranslation from "../hooks/useTranslation"
import findLangIndex from "../helpers/findLangIndex";
import createHtml from "../helpers/createHtml";
import useGetBrowserLanguage from "../hooks/useGetBrowserLanguage";

import "../styles/global.scss"
import styles from "./index.module.scss"

import TitleBlockShadeL from "../assets/images/pages/home/TitleBlockShadeL.svg"
import TitleBlockShadeR from "../assets/images/pages/home/TitleBlockShadeR.svg"
import TitleBlockBgCity from "../assets/images/pages/home/TitleBlockBgCity.svg"
import TitleBlockBgMountain from "../assets/images/pages/home/TitleBlockBgMountain.svg"
import Title from "../assets/images/pages/home/Title.svg"
import TitleHeroSilhouettes from "../assets/images/pages/home/TitleHeroSilhouettes.svg"
import TitleHeroes from "../assets/images/pages/home/TitleHeroes.svg"
import BubbleArrow1 from "../assets/images/pages/home/BubbleArrow1.svg"
import TitleBlockDownArrow from "../assets/images/pages/home/TitleBlockDownArrow.svg"
import TitleBlockDownArrowSmall from "../assets/images/pages/home/TitleBlockDownArrowSmall.svg"
import SuperpowersBlockImg from "../assets/images/pages/home/SuperpowersBlockImg.svg"
import ServicesBlockImg from "../assets/images/pages/home/ServicesBlockImg.svg"
import SolutionsBlockImg from "../assets/images/pages/home/SolutionsBlockImg.svg"
import JoinUsBlockImg from "../assets/images/pages/home/JoinUsBlockImg.svg"
import ClientsBlockWaves from "../assets/images/pages/home/ClientsBlockWaves.svg"
import ClientsBlockPlanet from "../assets/images/pages/home/ClientsBlockPlanet.svg"
import ClientsBlockHero from "../assets/images/pages/home/ClientsBlockHero.svg"

const Home = () => {
  const { t } = useTranslation("mainPage");

  const language = useGetBrowserLanguage();

  const { allMarkdownRemark } = useStaticQuery(graphql`
    query HomePageData {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(home-page)/" } }
      ) {
        edges {
          node {
            fileAbsolutePath
            frontmatter {
              title_block
              our_superpowers_category
              our_superpowers_title
              our_superpowers_text
              services_category
              services_title
              services_text
              solutions_category
              solutions_title
              solutions_text
              join_us_category
              join_us_title
              join_us_text
            }
          }
        }
      }
    }
  `);

  const remarkObj = useMemo(() => remark().use(remarkHtml), []);

  const pageData = useMemo(() => {
    const langIdxEn = findLangIndex(allMarkdownRemark?.edges, "home-page/home-page.md", "en");

    return {
      en: {
        titleBlock: remarkObj
          .processSync(
            allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.title_block
          ),
        ourSuperpowers: {
          category: allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.our_superpowers_category,
          title: allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.our_superpowers_title,
          text: remarkObj
            .processSync(
              allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.our_superpowers_text
            ),
        },
        services: {
          category: allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.services_category,
          title: allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.services_title,
          text: remarkObj
            .processSync(
              allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.services_text
            ),
        },
        solutions: {
          category: allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.solutions_category,
          title: allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.solutions_title,
          text: remarkObj
            .processSync(
              allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.solutions_text
            ),
        },
        joinUs: {
          category: allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.join_us_category,
          title: allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.join_us_title,
          text: remarkObj
            .processSync(
              allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.join_us_text
            ),
        }
      },
    };
  }, [remarkObj, allMarkdownRemark]);

  const currLangData = pageData.en

  return (
    <Layout>
      <div className={styles.mainBox}>
        <div className={styles.titleBlock__body}>
          <TitleBlockShadeL className={styles.titleBlock__shadeL} />
          <TitleBlockShadeR className={styles.titleBlock__shadeR} />
          <TitleBlockBgCity className={styles.titleBlock__city} />
          <TitleBlockBgMountain className={styles.titleBlock__mountain} />
          <div className={styles.titleBlock__content}>
            <TitleHeroSilhouettes
              className={styles.titleBlock__content__silhouettes}
            />
            <Title className={styles.titleBlock__content__title} />
            <TitleHeroes className={styles.titleBlock__content__heroes} />
            <div className={styles.titleBlock__content__bubble}>
              <BubbleArrow1
                className={styles.titleBlock__content__bubble__arrow}
              />
              <div
                className={styles.titleBlock__content__bubble__text}
                dangerouslySetInnerHTML={createHtml(currLangData.titleBlock)}
              />
              <ContinueLink to="/about-us#contactUs" label={t("titleBlock.contactUs")} />
            </div>
            <TitleBlockDownArrow
              className={styles.titleBlock__content__arrow}
            />
            <TitleBlockDownArrowSmall
              className={styles.titleBlock__content__arrowSmall}
            />
          </div>
        </div>
      </div>
      <InfoBlock
        className={styles.superpowersBlock}
        category={currLangData.ourSuperpowers.category}
        title={currLangData.ourSuperpowers.title}
        text={<div dangerouslySetInnerHTML={createHtml(currLangData.ourSuperpowers.text)} />}
        link="/our-superpowers"
        image={<SuperpowersBlockImg />}
      />
      <InfoBlock
        className={styles.servicesBlock}
        category={currLangData.services.category}
        title={currLangData.services.title}
        text={<div dangerouslySetInnerHTML={createHtml(currLangData.services.text)} />}
        link="/services"
        image={<ServicesBlockImg />}
        right
      />
      <InfoBlock
        className={styles.solutionsBlock}
        category={currLangData.solutions.category}
        title={currLangData.solutions.title}
        text={<div dangerouslySetInnerHTML={createHtml(currLangData.solutions.text)} />}
        link="/solutions"
        image={<SolutionsBlockImg />}
      />
      <InfoBlock
        className={styles.joinUsBlock}
        category={currLangData.joinUs.category}
        title={currLangData.joinUs.title}
        text={<div dangerouslySetInnerHTML={createHtml(currLangData.joinUs.text)} />}
        link="/join-us"
        image={<JoinUsBlockImg />}
        right
      />
      <div className={styles.clientsBlock}>
        <ClientsBlockWaves className={styles.clientsBlock__waves} />
        <ClientsBlockPlanet className={styles.clientsBlock__planet} />
        <div className={styles.clientsBlock__content}>
          <ClientsBlock />
          <div className={styles.clientsBlock__content__centerTitle}>
            {t("contactUs.title")}
          </div>
          <div
            id="contactUs"
            className={styles.clientsBlock__content__contactBlock}
          >
            <div
              className={styles.clientsBlock__content__contactBlock__heroBox}
            >
              <ClientsBlockHero
                className={
                  styles.clientsBlock__content__contactBlock__heroBox__hero
                }
              />
            </div>
            <ContactForm className={styles.contactForm} />
            <ContactInfo
              className={styles.clientsBlock__content__contactBlock__infoBox}
            />
          </div>
        </div>
      </div>
      <GoogleMaps />
    </Layout>
  )
}

export default Home
